.carousel {
  position: relative;
}

.carousel [role="list"] {
  padding: 0;
  list-style: none;
}

.carousel-scroll-container {
  /* Enable horizontal scrolling */
  overflow-x: auto;

  /* Enable horizontal scroll snap */
  scroll-snap-type: x proximity;

  /* Smoothly snap from one focal point to another */
  scroll-behavior: smooth;
}

.carousel-media {
  /* Arrange media horizontally */
  display: flex;
  gap: 1rem;
}

.carousel-item {
  /* Limit the height of each media item */
  /* height: 300px; */

  /* Prevent media from shrinking */
  flex-shrink: 0;

  /* The focal point for each item is the center */
  scroll-snap-align: center;
}

.carousel-item:first-of-type {
  /* Allow users to fully scroll to the start */
  scroll-snap-align: start;
}

.carousel-item:last-of-type {
  /* Allow users to fully scroll to the end */
  scroll-snap-align: end;
}

/* .carousel-item > *,
.carousel-item :is(figure, picture, img) {
  height: 100%;
} */

.carousel-item img {
  /* Responsive width based on aspect ratio */
  width: auto;
}

.slideshow .carousel-item {
  /* Full-width slides, taller height */
  height: 90vmin;
  width: 100%;
}

.carousel-control {
  --offset-x: 0.25rem;
  cursor: pointer;

  /* Anchor the controls relative to the outer wrapper */
  position: absolute;

  /* Center the controls vertically */
  top: 50%;
  transform: translateY(-50%);

  background: none;
}

/* Don't allow icons to be event targets */
.carousel-control * {
  pointer-events: none;
}

.carousel-control[data-direction="start"] {
  /* Same as left in LTR and right in RTL */
  inset-inline-start: var(--offset-x);
}

.carousel-control[data-direction="end"] {
  /* Same as right in LTR and left in RTL */
  inset-inline-end: var(--offset-x);
}

[dir="rtl"] .carousel-control {
  transform: translateY(-50%) scale(-1);
}

.carousel-control[aria-disabled="true"] {
  opacity: .25;
  cursor: not-allowed;
}