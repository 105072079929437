@import "_reset.css";
@import "_dialog.css";
@import "../vendor/carousel/carousel.css";

/* Fonts
----------------------------------------------------------------*/

/* courier-prime-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/courier-prime-v7-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/courier-prime-v7-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nanum-pen-script-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Nanum Pen Script';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nanum-pen-script-v19-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/nanum-pen-script-v19-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* maiden-orange-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Maiden Orange';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/maiden-orange-v25-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/maiden-orange-v25-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Styles
---------------------------------------------------------------- */

:root {
  --serif-font: 'Maiden Orange', sans-serif;
  --script-font: 'Nanum Pen Script', sans-serif;
  --typewriter-font: 'Courier Prime', monospace;
  --page-width: 66rem;
  --page-padding: 1rem;
}

html {
  background-color: #f4dda3;
}

body.home {
  background-image: linear-gradient(#f4dda3, #fff 600px);
}
@media (min-width: 950px) {
  body.home {
    background: #fff url(../images/background.jpg) 50% 0 / 2367px repeat-x;
  }  
}

.page-wrapper {
  padding-top: 1em;
  width: 100vw;
  overflow-x: hidden;
}

main,
footer {
  font-family: var(--script-font);
  font-size: 1.75em;
}

header {
  font-family: var(--serif-font);
  text-align: center;
}

.logo {
  padding: 0 var(--page-padding);
}
.logo img {
  margin: 0 auto;
}

/* .logo {
  font-family: var(--serif-font);
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logo span {
  -webkit-text-stroke: 2px rgba(0,0,0,1);
  text-shadow: 0 7px 0 rgba(0,0,0,0.5);
  font-size: clamp(1rem, 8.5rem, 8.5rem);
}
.logo span:first-of-type { order: 1; }
.logo span:last-of-type { order: 3; }
.logo img { order: 2; position: relative; top: -.2em; max-width: 20%; } */


/* Video
---------------------------------------------------------------- */

#video {
  width: calc(var(--page-width)*.85);
  padding: 0 var(--page-padding);
  max-width: 100%;
  margin: 1em auto 2em;
  position: relative;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: .35em .5em 0 rgba(0,0,0,.6);
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#video::before {
  content: url(../images/cop.svg);
  position: absolute;
  top: -70px;
  left: -320px;
  transform: scale(1.05);
}


/* Actions
---------------------------------------------------------------- */
#actions {
  max-width: var(--page-width);
  padding: 0 var(--page-padding);
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  align-items: top;
  position: relative;
}
.action-button {
  padding: .25em 0 0 .5em;
  color: #fff;
  font-family: var(--serif-font);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1em;
  line-height: 1.1;
  border: 3px solid #fff;
  border-radius: 2px;
  box-shadow: 6px 11px 0 0 rgba(96,44,0,0.28);
  display: flex;
  align-items: center;
  max-height: 3em;
  flex: 1;
  min-width: 220px;
}
@media (min-width: 1024px) {
  .action-button {
    font-size: 1.55em;
  }
}
.action-button:nth-child(1) { background-color: #d54930; }
.action-button:nth-child(2) { background-color: #1d4f74; }
.action-button:nth-child(3) { background-color: #e89d48; }

.action-button img { position: relative; max-width: 20%; }
@media (min-width: 600px) {
  .action-button img { max-width: 40%; }
}
.action-button:nth-child(1) img { top: -.75em; right: .3em; }
.action-button:nth-child(2) img { top: -.15em; right: 0; }
.action-button:nth-child(3) img { right: -.2em; }


/* Content
---------------------------------------------------------------- */
.content-wrapper {
  max-width: calc(var(--page-width)*.85);
  padding: 0 var(--page-padding);
  margin: 2em auto;
}
@media (min-width: 800px) {
  .content-wrapper {
    display: grid;
    grid-template-columns: 5fr 4fr;
    gap: 1em;
  }
  #common-crimes {
    background: transparent url(../images/post-it.svg) 0 0 / cover no-repeat;
    padding: 1.5em 8% 0 10%;
    aspect-ratio: 1/1;
  }
}
#content * + * {
  margin-top: 1em;
}
#common-crimes {
  padding-top: 1.5em;
}
#common-crimes h2 {
  color: #d54930;
  font-size: 1.25em;
  margin: 0 0 .15em;
  font-family: var(--serif-font);
  text-transform: uppercase;
  text-align: center;
}
#common-crimes ul {
  color: #484f5f;
  font-size: .9em;
  margin-left: .75em;
}
#common-crimes li {
  line-height: 1;
}
#common-crimes li + li {
  margin-top: .35em;
}

@media (min-width: 890px) {
  .content-wrapper {
    display: grid;
    grid-template-columns: 5fr 4fr;
    gap: 1em;
  }
}


/* Criminals
---------------------------------------------------------------- */
#criminals {
  background-color: #439197;
  color: #fff;
  padding: 2em 0;
  position: relative;
}
#criminals h2 {
  font-family: var(--serif-font);
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8em;
  color: #d54930;
  -webkit-text-stroke: 1px #fff;
  text-shadow: 0 4px 0 0 rgba(0,0,0,.3);
}
@media (min-width: 660px) {
  #criminals h2 {
    font-size: 3em;
    -webkit-text-stroke: 2px #fff;
  }
}

.police-tape {
  position: absolute;
  left: -5px;
  min-width: 1280px;
  max-width: 105%;
}
.police-tape:nth-of-type(1) { top: -25px; transform: rotate(-1deg); }
.police-tape:nth-of-type(2) { bottom: -35px; transform: rotate(-2.5deg); }
.police-tape:nth-of-type(3) { bottom: -15px; transform: rotate(.6deg); }

#criminals .carousel {
  width: var(--page-width);
  max-width: 90%;
  margin: auto;
}
#criminals .carousel-control {
  --offset-x: -3em;
}
#criminals .carousel-scroll-container {
  cursor: grab;
}


/* Carousel Items */
.criminal {
  width: 14em;
  padding: .5em;
  flex: 0 0 auto;
  font-size: .7em;
  line-height: 1;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.criminal-image {
  position: relative;
}
.criminal-image img {
  position: relative;
  z-index: 10;
  border: 13px solid #fcf8e4;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  position: relative;
  top: -7px;
  left: -8px;
}
.criminal-image::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.3);
  transform: rotate(2deg);
  z-index: 0;
}
.carousel-item:nth-child(1) .criminal-image { transform: rotate(-2deg); }
.carousel-item:nth-child(2) .criminal-image { transform: rotate(-1deg); }
.carousel-item:nth-child(3) .criminal-image { transform: rotate(1.5deg); }
.carousel-item:nth-child(4) .criminal-image { transform: rotate(-.5deg); }
.carousel-item:nth-child(5) .criminal-image { transform: rotate(1.5deg); }
.carousel-item:nth-child(6) .criminal-image { transform: rotate(-.5deg); }
.carousel-item:nth-child(7) .criminal-image { transform: rotate(-2deg); }
.carousel-item:nth-child(8) .criminal-image { transform: rotate(1deg); }
.carousel-item:nth-child(9) .criminal-image { transform: rotate(-.5deg); }
.carousel-item:nth-child(10) .criminal-image { transform: rotate(-2deg); }


/* Footer
---------------------------------------------------------------- */
footer[role=contentinfo] {
  margin: auto;
  padding: 2em 0;
  font-family: var(--serif-font);
  font-size: 2.5em;
  background-color: #f4dda3;
}
footer[role=contentinfo] p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: .5em;
}
footer[role=contentinfo] a {
  text-decoration: none;
  color: #205077;
}
footer[role=contentinfo] a:hover {
  text-decoration: underline;
}
footer[role=contentinfo] a>img {
  max-width: 30%;
}


/* Dialogs
---------------------------------------------------------------- */
.dialog-content {
  font-family: var(--typewriter-font);
  font-size: 1.1em;
  background-color: #fffff6;
}
.dialog-content > * + * {
  margin-top: 1em;
}
.dialog-content h1 {
  font-family: var(--serif-font);
  color: #439197;
  font-size: 2.5em;
  border-bottom: 3px double #e0dbbc;
}
.dialog-content ul {
  padding-left: 1.5em;
}
.mgs-logo img {
  display: block;
  margin: auto;
}
.dialog-close {
  font-family: var(--script-font);
  font-size: 3em;
  top: .25em;
  right: .25em;
  padding: 0;
  width: 1em;
  height: 1em;
}

/* Helpers
---------------------------------------------------------------- */
.link-like {
  background-color: transparent;
  text-decoration: underline;
  border: 0;
  margin: 0;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
.text-quiet {
  opacity: .6;
}
.text-small {
  font-size: 0.8em;
}


/* Forms
---------------------------------------------------------------- */

form * + p {
  margin-top: 1.25em;
}

form input[type=text] {
  font: inherit;
  padding: 0.25em;
  border: 1px solid #aaa;
  width: 100%;
  color: #439197;
}
form input[type=file] {
  display: block;
}

.dialog-content form ul {
  list-style: none;
  margin: .25em 0 0;
  padding: 0;
}
form li {
  display: flex;
  gap: .5em;
  padding: 0;
  color: #439197;
  font-size: .9em;
}
form li + li {
  margin-top: .25em;
}
fieldset {
  border: none;
}
fieldset.scrollable {
  max-height: 8.5em;
  overflow-y: scroll;
  background-color: #faf6de;
  padding: .5em;
  border-radius: .25em;
  border: 1px solid #ede9ce;
}
.error {
  font-size: .9em;
  background-color: #ffebeb;
  padding: .5em 1em;
  border-radius: .25em;
  border: 1px solid #ce221e;
  color: #ce221e;
}
  


/* Shareables
---------------------------------------------------------------- */
.bg-wood {
  background: #f4dda3 url(../images/bg-wood.png) 50% 50% / cover no-repeat;
}
.shareable {
  width: 800px;
  height: 800px;
  margin: auto;
}
#image-wrapper {
  padding-top: 1em;
  width: 100%;
  height: 100%;
}
.paper {
  width: 630px;
  height: 740px;
  margin: auto;
  /* transform: rotate(-.5deg); */
  background-color: #fcf8e4;
  background-image: linear-gradient(180deg, #FCF8E3 0%, #FFFFFF 100%);
  box-shadow: rgba(116, 67, 14, 0.16) .7em .7em 0;
  padding: .6em;
  position: relative;
  display: grid;
  grid-template-rows: 90px auto;
}
.paper::after {
  content: url(../images/logo-shield.svg);
  position: absolute;
  bottom: 1%;
  right: 12%;
  opacity: .2;
  transform: scale(1.4);
  z-index: 0;
}
.paper-content {
  border: 2px solid #836725;
  position: relative;
  z-index: 10;
}
.shareable h1 {
  color: #fff;
  text-transform: uppercase;
  padding: .5em 0 .25em;
  margin: 0 0 0;
  line-height: .5;
  letter-spacing: .05em;
  font-family: var(--serif-font);
  font-size: 4.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25em;
}
.shareable h1::after,
.shareable h1::before {
  content: '';
  position: relative;
  top: -.1em;
}

.text-center {
  text-align: center;
}
.website-link {
  font-family: var(--typewriter-font);
  margin-top: 0;
}
.website-link a {
  text-decoration: none;
  color: #000;
}

.button,
.image-button {
  padding: .25em .75em 0;
  color: #fff;
  font-family: var(--serif-font);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.25em;
  line-height: 1.1;
  border: 3px solid #fff;
  border-radius: 2px;
  box-shadow: 6px 11px 0 0 rgba(96,44,0,0.28);
  align-items: center;
  background-color: #d54930;
}
.button {
  box-shadow: none;
}

.avatar img {
  aspect-ratio: 1/1;
  object-fit: contain;
  background-color: #fff;
}

/* Confession */
.confession h1 {
  background-color: #836725;
}
.confession h1::after,
.confession h1::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="52" height="33"%3E%3Cpath fill="%23F0CD74" fill-rule="nonzero" d="M50.954.5a1 1 0 0 1 0 2H1.046a1 1 0 0 1 0-2h49.908Zm0 15a1 1 0 0 1 0 2H1.046a1 1 0 0 1 0-2h49.908Zm0 15a1 1 0 0 1 0 2H1.046a1 1 0 0 1 0-2h49.908Z"/%3E%3C/svg%3E');
}
.confession dl {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr auto;
}
.confession .avatar {
  grid-column: 1/2;
  grid-row: 1/3;
  border: 2px solid #836725;
  border-width: 0 2px 2px 0;
}
.confession .avatar dt { display: none; }
.confession dl > div:nth-child(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}
.confession dl > div:nth-child(3) {
  grid-column: 2/3;
  grid-row: 2/3;
  border: 2px solid #836725;
  border-width: 2px 0 2px;
}
.confession dl > div:nth-child(4) {
  grid-column: 1/3;
  grid-row: 3/4;
}
.confession dl > div:not(.avatar) {
  padding: .35em .75em;
}
.confession dt {
  font-family: var(--typewriter-font);
  font-size: .8em;
}
.confession dd {
  color: #3d6b8f;
  font-size: 1em;
  line-height: 1.2;
}


/* Wanted Poster */
.wanted h1 {
  background-color: #d54930;
}
.wanted h1::before,
.wanted h1::after {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="58" height="60"%3E%3Cpath fill="%23FCF9E8" fill-rule="evenodd" d="M49.474 24.765a87.138 87.138 0 0 0-13.706-2.793c-1.633-4.565-3.267-9.13-4.9-13.704 1.674-.67 2.876-2.452 2.876-4.324C33.744 1.581 31.83 0 29.466 0c-2.365 0-4.289 1.581-4.289 3.944 0 1.602.892 3.133 2.185 3.963-1.503 4.485-3.026 9-4.55 13.534-4.828.19-9.657.84-14.406 1.952-.492-1.742-2.134-2.852-4.119-2.852C1.924 20.54 0 22.112 0 24.475c0 2.362 1.924 4.624 4.287 4.624 1.153 0 2.195-.54 2.967-1.372a137.317 137.317 0 0 0 10.269 9.47c-1.563 4.685-3.136 9.35-4.689 13.974-.32-.06-.641-.1-.982-.1-2.364 0-4.288 1.582-4.288 3.944s1.924 4.614 4.288 4.614c2.365 0 4.288-2.252 4.288-4.614 0-.45-.09-.86-.22-1.242a794.787 794.787 0 0 0 11.993-8.618 174.387 174.387 0 0 0 14.878 9.34c-.06.28-.1.57-.1.88 0 2.362 1.924 4.625 4.289 4.625 2.363 0 4.287-2.263 4.287-4.625s-1.924-3.934-4.287-3.934c-.221 0-.431.02-.652.05-1.903-5.335-3.808-10.66-5.721-15.996a314.993 314.993 0 0 0 9.568-7.847c.771 1.22 2.074 2.101 3.547 2.101 2.364 0 4.278-2.262 4.278-4.624 0-2.362-1.914-3.944-4.278-3.944-2.245 0-4.068 1.421-4.248 3.584" opacity=".404"/%3E%3C/svg%3E')
}
.wanted .paper-content {
  font-family: var(--typewriter-font);
  padding: 0 1em;
  max-height: 100%;
  overflow: hidden;
}
.wanted h2 {
  text-align: center;
  margin: .5em 0;
  font-size: 1.35em;
}
.wanted .avatar {
  background: transparent url(../images/height-lines.svg) 50% 50% / contain no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wanted .avatar img {
  width: 60%;
  border: 1px solid #836725;
}
.wanted .paper-content p {
  font-size: .85em;
  margin-top: 1em;
}
.wanted strong {
  color: #d54930;
}


/* Conviction */
.conviction .paper::after {
  top: 30%;
}
.conviction h1 {
  background-color: #4b4e5b;
}
.conviction h1::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="52" height="50"%3E%3Cpath fill="%23FEFEFB" fill-rule="evenodd" d="M37.557 16.644a53.366 53.366 0 0 1-1.288 8.312c-.855.909-1.913 1.736-2.78 2.643.772 1.153 1.234 2.292 2.006 3.445 5.79-.054 11.35 1.737 16.3 4.706.054 4.664.081 9.396.081 14.17a2246.4 2246.4 0 0 1-50.945-.638A1225.6 1225.6 0 0 0 .648 33.12a35.321 35.321 0 0 1 15.294-2.414c.435-.88.855-1.64 1.275-2.522-.732-.772-1.654-1.6-2.658-1.966a50.247 50.247 0 0 1-1.084-9.004c-.15-.054-.313-.161-.501-.283a4.985 4.985 0 0 1-1.425-1.44 4.838 4.838 0 0 1-.772-2.196c-.095-.853.066-1.761.528-2.494.462-.733 1.248-1.288 2.116-1.37.134-.013.27-.013.406 0a46.925 46.925 0 0 1 1.98-8.76c7.091-1.355 14.306-.854 21.166 1.344.447 2.534.692 5.165.76 7.823.557-.42 1.302-.542 1.967-.366.826.217 1.517.867 1.871 1.668.34.8.366 1.708.149 2.549a4.939 4.939 0 0 1-1.1 2.047 5.025 5.025 0 0 1-1.612 1.207c-.733.354-1.058.366-1.451-.298"/%3E%3C/svg%3E');
}
.conviction h1::after {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="40" height="55"%3E%3Cpath fill="%23FEFEFB" fill-rule="evenodd" d="M27.833 53.8c-2.153-4.794-4.938-9.365-8.295-13.293-.059.058-.133.102-.191.16 1.51-2.683 3.724-5.086 6.068-6.992.03.294.513.294.543.602 3.166 1.26 6.903 1.406 10.054.087.265-2.974.88-6.127 1.158-9.086 1.35-.528 2.331-2.464 1.847-3.913-1.304-.91-2.258-2.316-2.725-3.9.63-.307 1.597-.893 2.212-1.187.602-2.257 1.423-5.16.528-7.3-1.128-2.681-3.342-4.735-5.804-6.097-2.463-1.377-4.734-2.842-7.52-2.77-3.209.089-6.39 1.334-8.882 3.474-2.492 2.155-5.145 5.908-4.88 9.438.205 2.653 1.026 5.337 1.715 7.888-.939 1.933-1.832 3.867-2.786 5.788-.702 1.42-2.168 1.831-3.355 2.813-2.609 2.14-4.588 5.117-5.746 8.37C-.058 43.012-.028 48.7.924 54.108c-2.008.013 32.304-.395 26.91-.309Z"/%3E%3C/svg%3E');
}
.conviction .paper-content {
  font-family: var(--typewriter-font);
  padding: 1em 1em 0;
  height: 100%;
}
.conviction h2 {
  font-family: var(--script-font);
  margin-top: 72%;
  font-size: 1.8em;
  line-height: 0.8;
  color: #1d4f74;
}
.conviction .avatar {
  background-color: #fcf8e4;
  border: 1px solid #CEBD94;
  padding: 20px;
  position: absolute;
  top: -30px;
  left: -20px;
  transform: rotate(-2deg);
  width: 75%;
  box-shadow: rgba(116, 67, 14, 0.16) 6px 8px 0;
}
.conviction .avatar::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="87" height="96"%3E%3Cdefs%3E%3Cpath id="a" d="M0 0h87v91l-25.968 4.111L16 36.164v-1.411 6.18l-15.917.56z"/%3E%3C/defs%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cmask id="b" fill="%23fff"%3E%3Cuse xlink:href="%23a"/%3E%3C/mask%3E%3Cpath stroke="%23000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".35" stroke-width="6" d="M16.58 44.527h0l-6.266-7.208c-5.323-6.124-4.674-15.403 1.45-20.727 6.123-5.323 15.403-4.674 20.727 1.45l45.621 52.482c3.956 4.55 3.474 11.447-1.077 15.402-4.55 3.956-11.446 3.474-15.402-1.077l-41.62-47.877h0" mask="url(%23b)"/%3E%3Cpath stroke="%23BCBCBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" d="M16.58 41.527h0l-6.266-7.208c-5.323-6.124-4.674-15.403 1.45-20.727 6.123-5.323 15.403-4.674 20.727 1.45l45.621 52.482c3.956 4.55 3.474 11.447-1.077 15.402-4.55 3.956-11.446 3.474-15.402-1.077l-41.62-47.877h0" mask="url(%23b)"/%3E%3C/g%3E%3C/svg%3E');
  position: absolute;
  top: -41px;
  left: -9px;
}
.conviction .avatar img {
  border: 1px solid #FCF8E4;
}
.conviction .paper-content p {
  font-size: .75em;
  max-height: 6.5em;
  overflow: hidden;
}
.conviction strong {
  color: #d54930;
}